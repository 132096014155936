<style lang="scss" scoped>
  @import "./new-product.modal";

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 70vw;
  min-height: 105vh;
  border-radius: 12px;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.6em;
}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.edit-product__description-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-product__description-input-wrapper {
  padding: 2em;
  margin-top: 2em;
}
.edit-description-input {
  width: 26.5vw;
}
.edit-product__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
  margin-bottom: 10px;
}
.edit-product__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 2em;
  margin-bottom: 2em;
}

.edit-product__category-input-wrapper {}
.toggle-button-wrapper {
  padding: 0em 2em;
}
.update-buttons__container {
  display: flex;
  flex-direction: row-reverse;
  padding: 1vh 2em 3vh 2em;
}
.upload-img-icon {
  width: 128px;
  height: 128px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.image-upload {
  display: flex;
  align-items: center;
  width: 26.5vw;
}
.image-upload > input {
  display: none;
}

.image-upload img {
  width: 40px;
  cursor: pointer;
}
.edit-image-icon {
  position: relative;
  top: 50px;
  cursor: pointer;
  color: #505050;
}
.remove-image-icon {
  cursor: pointer;
  color: #CB595B;
}
.remove-icon {
  color: #CB595B;
  margin-left: 10px;
  cursor: pointer;
}

/deep/
.multiselect {
  width: 100%;
}
.edit-product__category-input-width{width: 40%;}
.code-input {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .edit-product__actions-container{flex-direction: column;}
  .multiselect{width: 100%;}
  .edit-product__category-input-wrapper{width: 100%;}
  .edit-product__category-input-width{width: 100%;}
  .modal-content-container{width: 110vw; height: 105vh; overflow: auto;}
  .action-buttons{width: 45%;}
}

</style>

<template>
  <div class="modal-mask">
    <cc-loader-full v-show="loading"/>
    <div class="modal-content-container">
      <div class="new-product__header-container">
        <span class="new-product__header-title">
          {{ title }}
        </span>
        <span @click="closeDialog" class="material-icons new-product__closebtn-icon">
          cancel
        </span>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form action="" @submit.prevent="handleSubmit(save)">
          <ValidationProvider rules="required" v-slot="v">
            <div class="edit-product__description-input-container">
              <div class="edit-product__description-input-wrapper">
                <label class="edit-product__input-title">Descrição<span style="color:red !important">*</span></label>
                <cc-input v-model="product.name"
                          class="edit-description-input"
                          placeholder="Descrição do seu produto.Exemplo:Macarrão Instantâneo Sabor Carne" />
                <span class="error">{{ v.errors[0] }}</span>
              </div>
              <form ref="formHTML" class="image-upload">
                <div v-if="!showNewImage" class="upload-img-icon" :style="getProductImage(product)">
                </div>
                <div v-else class="upload-img-icon" :style="getProductImage(product)">
                </div>
                <label for="file-input">
                  <span class="material-icons-outlined edit-image-icon">
                    edit
                  </span>
                  <!-- <span class="material-icons-outlined remove-image-icon">
                    delete
                  </span> -->
                </label>
                <input id="file-input" type="file" @change="uploadImage($event, product)"/>
              </form>
            </div>
           </ValidationProvider>
           <div class="edit-product__actions-container">
             <div class="edit-product__category-input-wrapper edit-product__category-input-width">
               <label class="edit-product__input-title">Categoria:<span style="color:red !important">*</span></label>
               <ValidationProvider  rules="required" v-slot="v">
                 <cc-select-v2 class="multiselect"
                               :placeholder="'Selecione a categoria'"
                               :tracker="'cat_id'"
                               :text="'cat_descricao'"
                               :options="categories"
                               v-model="product.category" />
                 <span class="error">{{ v.errors[0] }}</span>
               </ValidationProvider>
             </div>
             <div style="margin-top: 6px;" class="edit-product__category-input-wrapper edit-product__category-input-width">
               <label class="edit-product__input-title">Código:<span style="color:red !important">*</span></label>
               <ValidationProvider rules="required" v-slot="v">
                 <cc-input class="code-input"
                           placeholder="Digite uma Referência"
                           v-model="productCode" />
                 <span class="error">{{ v.errors[0] }}</span>
               </ValidationProvider>
             </div>
           </div>
           <div v-if="product.is_generic" class="edit-product__actions-container">
            <div class="edit-product__buy-unit-input-wrapper edit-product__category-input-width">
              <label class="edit-product__input-title">Unidade de Compra<span style="color:red !important">*</span></label>
              <ValidationProvider rules="required" v-slot="v">
                <cc-input class="code-input"
                          v-model="product.purchase_unit" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
           </div>
           <div v-else class="edit-product__actions-container">
            <div class="edit-product__only-unit-input-wrapper edit-product__category-input-width">
              <label class="edit-product__input-title">Unidade<span style="color:red !important">*</span></label>
              <ValidationProvider rules="required" v-slot="v">
                <cc-select-v2 :tracker="'value'"
                              class="multiselect"
                              :placeholder="'Selecione a unidade'"
                              :text="'name'"
                              v-model="wrap" :options="wraps" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="edit-product__qty-package-input-wrapper edit-product__category-input-width">
              <label class="edit-product__input-title">Qtd.Embalagem<span style="color:red !important">*</span></label>
              <ValidationProvider rules="required" v-slot="v">
                <cc-input class="code-input" 
                          v-model="qtdEmbalagemModal" />
                <span class="error">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
           </div>
           <div class="row pt-2">
             <div v-if="error_messages" class="col-md-12 col-lg-12">
               <h4 class="py-4 text-center text-danger">{{ error_messages }}</h4>
             </div>
           </div>
           <button id="submit-product" class="hidden"></button>
        </form>
      </ValidationObserver>
      <div class="toggle-button-wrapper" slot="header-actions">
        <toggle-button v-model="product.is_generic"
                       :color="'#ff7110'"
                       :height="40"
                       :labels="{ checked: 'G E N É R I C O', unchecked: 'NÃO   G E N É R I C O' }"
                       :width="product.is_generic ? 140 : 140"/>
      </div>
      <div class="update-buttons__container">
        <Button v-if="product.id" class="action-buttons" title="Adicionar Produto" :text="'Atualizar'" :action="save" style="margin-left: 15px;"/>
        <Button v-else class="action-buttons" title="Adicionar Produto" :text="'Salvar'" :action="save" style="margin-left: 15px;"/>
        <Button class="action-buttons" title="Adicionar Produto" :text="'Cancelar'" :action="closeDialog" style="margin-left: 15px;"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from '@/constants/constants';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ProductService from '@/services/v3/management/product.service';
import CategoryService from '@/services/v3/management/category.service';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Button from '@/components/ui/buttons/StandardButton.vue'

export default {
    mixins: [ loaderMixin ],
    props: {
        product: {
            type: Object,
            required: true
        },
        to_request: {
            type: Boolean,
            default: false
        },
        closeDialog: {
            type: Function,
        },
    },
    components: {
	    ValidationProvider,
	    ValidationObserver,
      Button,
    },
    data() {
        return {
            edit_internal_code_allowed: false,
            title: this.product.id ? 'Editar produto' : 'Adicionar produto',
            modal: {
                title: "produto",
                submit: () => document.getElementById("submit-product").click(),
                submit_text: this.product.id ? 'Atualizar' : 'Salvar',
                subtitle: 'Crie ou altere informações do produto',
                icon_title: 'far fa-edit',
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                style: {
                    width: "50%"
                }
            },
            wraps: [
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Duzia', value: 'DZ' }
            ],
            svc: new ProductService(),
            cat_svc: new CategoryService(),
            loading: false,
            wrap: null,
            qtdEmbalagemModal: 1,
            error_messages: '',
            stores: [],
            categories: [],
            product_service: new ProductService(),
            showNewImage: false,
            productCode: ''
        }
    },
    created () {
      if (!this.product.id) {
        this.loading = true
        this.svc.getCod().then(resp => {
          this.productCode = resp.data.sequence
          this.loading = false
        })
      } else this.productCode = this.product.ean
    },
    methods: {
      getProductImage(product) {
        return `background-image: url(${product.pro_imagem}?lastmod=${Date.now()})`
      },
      deleteImage() {},
      uploadImage(event, product) {
        let file = event.target.files[0];

        let img = new Image();
        img.src = URL.createObjectURL(file);

        img.addEventListener('load', () => {
          if (img.width >= 640 && img.height >= 460) {
            // continua com o upload
            this.present_loader('Enviando Imagem')

            let formData = new FormData();
            formData.append('id', product.id)
            formData.append('cover', file)

            axios
              .post(`${CONSTANTS.API_V3_URL}/admin/management/products/image`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                }
              })
              .then(res => {
                this.$swal.fire(
                  'Sucesso!',
                  'Imagem enviada com sucesso.',
                  'success'
                );
                this.$emit('reload')
                this.showNewImage = true
                this.getProductImage()
              });
          } else {
            this.$swal.fire(
             'Erro!',
             'A imagem deve ter pelo menos 640px de largura e 460px de altura.',
             'error'
            );
          }
        });
      },
        close() {
            this.$emit('close')
        },
        load_categories() {
            return this.cat_svc.list().then((response) => response.data).then((data) => {
                this.categories = data
                this.product.category = this.categories.find(cat => this.product.category && cat.id == this.product.category.id)
            })
        },
        save() {
          this.product.category.id = this.product.category.cat_id
            let data = {
                ...this.product,
                is_generic: !!this.product.is_generic,
                packing: this.wrap ? `${this.wrap.value} ${this.qtdEmbalagemModal}` : null
            }
            this.svc.save(data).then(res => res.data).then(()=>{
                this.loading = false;
                if(this.product.id) {
                    this.$swal.fire("Atualizado!", "Produto atualizado com sucesso.", "success");
                } else {
                    this.$swal.fire("Inserido!", "Produto inserido com sucesso.", "success");
                }
                this.$emit('reload')
                this.close()
            }).catch((err)=>{
                if(err.response && err.response.data && err.response.data.errors) {
                    let errors = err.response.data.errors
					this.error_messages = Object.values(errors).flat()[0]
				} else {
                    this.error_messages = 'Ops! Houve um erro ao salvar o produto, tente novamente mais tarde!'
				}
                this.loading = false
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        process_packing() {
            if(this.product.packing) {
                let pack = this.product.packing.split(" ")
                if(pack && pack.length > 1) {
                    this.qtdEmbalagemModal = pack[1]
                    this.wrap = this.wraps.find(w => w.value == pack[0])
                    this.$forceUpdate()
                }
            }
        }
    },
    watch: {
        'wrap.value' : function(new_val) {
            if(new_val == 'UN') {
                this.qtdEmbalagemModal = 1
            } else if(new_val == 'DZ') {
                this.qtdEmbalagemModal = 12
            }
        },
        'qtdEmbalagemModal': function(new_val) {
            if(new_val && new_val.match(/[^0-9]/g)) {
                this.qtdEmbalagemModal = this.qtdEmbalagemModal.replace(/[^0-9]/g, '')
            }
        },
        productCode (value) {
          this.product.ean = value
        }
    },
    mounted() {
        this.load_categories()
        this.process_packing()
    },
}
</script>
